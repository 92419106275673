import React, { useEffect, useState } from "react";
import Container from "../Container";
import logo from "../Assets/img/logo.png";
import {
  Box,
  ListItemButton,
  Typography,
  IconButton,
  Paper,
  Button,
  LinearProgress,
  Avatar,
} from "@mui/material";
import locationIcon from "../Assets/img/location.svg";
import mapIcon from "../Assets/img/map.svg";
import menuImg from "../Assets/img/Rectangle 34624081.png";
import rateImg from "../Assets/img/kapadoccia-lagos-entrance 1.png";
import leaf1 from "../Assets/img/leaf1.svg";
import leaf2 from "../Assets/img/leaf2.svg";
import leaf3 from "../Assets/img/leaf3.svg";
import leaf4 from "../Assets/img/leaf4.svg";
import vlogo from "../../assets/img/logo/logo2.svg";
import { getRestaurantDetails } from "../../assets/img/api";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Carousel from "../Components/Carousel";
import Menu from "./Menu";
import onlineIcon from "../Assets/img/online.svg";
import walkIcon from "../Assets/img/walk.svg";
import { BsChevronRight } from "react-icons/bs";

const Reservation = () => {
  const { uid, name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [matchingDay, setMatchingDay] = useState(null);

  const [darkMode, setDarkMode] = useState(false);

  const [data, setData] = useState([]);

  const [source, setSource] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    setSource(source);
  }, [location.search]);

  const handleGetRestaurantDetails = async () => {
    setIsLoading(true);
    await getRestaurantDetails(uid)
      .then((res) => {
        setIsLoading(false);

        setData(res?.data?.data);
        checkSchedule();
        console.log(res);
        setDarkMode(res?.data?.data?.dark_mode);
        if (res?.data?.status === false) {
          navigate("/not-found");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        navigate("/not-found");
      });
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);
  useEffect(() => {
    const referrer = document?.referrer;
    console.log("Referrer:", referrer);
  }, []);

  console.log(document, "Documet");
  const checkSchedule = () => {
    const now = new Date();
    const currentDay = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
      now.getMinutes()
    ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

    const matchingDay = data?.opening_hours?.find(
      (item) => item.day === currentDay
    );

    setMatchingDay(matchingDay);

    if (matchingDay) {
      const { open_time, close_time } = matchingDay;
      if (currentTime >= open_time && currentTime <= close_time) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    checkSchedule();
  }, [data]);

  const convertTo12HourFormat = (timeString) => {
    // Parse the time string into a Date object
    const time = new Date(`2000-01-01T${timeString}`);

    // Format the time in 12-hour format
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  };

  const [isWelcome, setIsWelcome] = useState(true);

  return (
    <>
      <Container>
        {isLoading ? (
          <>
            <Box
              className={darkMode ? "dark" : "light"}
              sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={vlogo} />
              <LinearProgress sx={{ width: "40%", mt: 3 }} />
            </Box>
          </>
        ) : (
          <>
            {isWelcome ? (
              <>
                <Box
                  className={darkMode ? "dark hide_scrollbar" : "light hide_scrollbar" }
                  sx={{
                    pb: 3,
                    height: "100vh",
                    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${
                      data?.images && data?.images[0]?.image
                    }')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow:'scroll'
                  }}
                >
                  {/* <img src={data?.logo} width={60} style={{color:''}} /> */}
                  <Box sx={{
                    width:'55px', height:'55px'  , background:`url('${data?.logo}')`, backgroundSize:'contain', backgroundPosition:'center', backgroundRepeat:'no-repeat', borderRadius:'5px'                }}/>
                  <Box sx={{ width: "80%", margin: "0 auto" }}>
                    <Typography
                      variant="h2"
                      sx={{
                        color: "#fff",
                        fontFamily: "butler",
                        fontSize: "25px",
                        textAlign: "center",
                        mt: 3,
                        fontWeight: 500,
                      }}
                    >
                      Welcome to {data?.spot_name}!
                    </Typography>
                    <Typography
                      sx={{
                        width: "90%",
                        textAlign: "center",
                        margin: "0 auto",
                        mt: 1,
                        color: "#f4f4f4",
                        lineHeight: "18px",
                      }}
                    >
                      To continue with your booking, please choose whether you
                      are a walk-in guest or making a reservation for a future
                      time.
                    </Typography>

                    <Box sx={{ mt: 6 }}>
                          <Link
                          to={isOpen &&  `/reservation/${name}/${uid}?source=${source}&walkin=true`} style={{              cursor:!isOpen && "not-allowed"}}
                        
                        >
                      <ListItemButton
                      disabled={!isOpen}
                        sx={{
                          bgcolor: "#151515",
                          border: "2px solid #fff",
                          borderRadius: "12px",
                          cursor:!isOpen && "not-allowed",
                          "&:disabled":{

                                backgroundColor: "#151515",
                          }

                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            columnGap: 2,
                            justifyContent: "space-between",
                            alignItems: "center",
                            py: 1,
                               height:'70px'
                          }}
                        >
                          <img src={walkIcon} width={45} />
                          <Box>
                      <Box sx={{display:'flex', justifyContent:'space-between'}}>
                              <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {md:"18px", xs:'16px'},
                                fontWeight: 500,
                                
                              }}
                            >
                              Walk in Guest
                            </Typography>
                        {
                          !isOpen && (
                                      <Typography
                              sx={{
                                color: "#A71200",
                                fontSize: '10px',
                                fontWeight: 500,
                                
                              }}
                            >
                           Restuarant closed
                            </Typography>
                          )
                        }
                      </Box>
                            <Typography
                              sx={{ color: "#fff", fontWeight: 300, mt: 0.5 , fontSize:{md:'14px', xs:'12px'}}}
                            >
                              Access and select menu items as a walk in guest.
                            </Typography>
                          </Box>
                          <BsChevronRight
                            style={{ color: "#fff", fontSize: "25px" }}
                          />
                        </Box>
                      </ListItemButton>
                      </Link>
                      <ListItemButton
                      onClick={()=>setIsWelcome(false)}
                        sx={{
                          bgcolor: "#ffffff",
                          border: "2px solid #fff",
                          borderRadius: "12px",
                          mt: 5,

                          "&:hover":{
                            bgcolor:'#f4f4f4'
                          }
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            columnGap: 2,
                            justifyContent: "space-between",
                            alignItems: "center",
                            py: 1,
                            height:'70px'
                          }}
                        >
                          <img src={onlineIcon} width={50} />
                          <Box>
                            <Typography
                              sx={{    fontSize: {md:"18px", xs:'16px'}, fontWeight: 500 }}
                            >
                              Online Reservation
                            </Typography>
                            <Typography sx={{ fontWeight: 300, mt: 0.5, fontSize:{md:'14px', xs:'12px'}}}>
                              Book a reservation for a future time.
                            </Typography>
                          </Box>
                          <BsChevronRight
                            style={{ color: "#151515", fontSize: "25px" }}
                          />
                        </Box>
                      </ListItemButton>

                      <Typography sx={{color:'#fff', fontSize:'12px', textAlign:'center', mt:12}}>Powered by Vibezsup</Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box className={darkMode ? "dark" : "light"} sx={{ pb: 3 }}>
                  <Box
                    sx={{
                      width: "100%",

                      display: "grid",
                      placeItems: "center",
                      bgcolor: darkMode ? "#000" : "#fff",
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                      margin: "0 auto",
                      height: "100px",
                      zIndex: 1000,
                    }}
                  >
                    <img src={data?.logo} width={50} />
                  </Box>
                  <Box sx={{ mt: 12.5, pt: 3 }}>
                    <Box
                      sx={{
                        height: "300px",
                        mx: { lg: 3, md: 3, sm: 2, xs: 2 },
                        borderRadius: 3,
                        overflow: "hidden",
                      }}
                    >
                      <Carousel images={data?.images} />
                    </Box>
                    <Box sx={{ mx: 3 }}>
                      <Box
                        sx={{
                          mt: 3,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography
                            className={darkMode ? "dark" : "light"}
                            sx={{
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {data?.spot_name}
                          </Typography>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              columnGap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img src={locationIcon} />
                            <Typography
                              color={darkMode ? "#F489FD" : "primary"}
                            >
                              {`${data?.short_location}, ${data?.city}`}
                            </Typography>
                          </Box>
                        </Box>
                        <a href={data?.map_link} target="_blank">
                          <IconButton>
                            <img src={mapIcon} />
                          </IconButton>
                        </a>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          className="three-line-ellipsis"
                          sx={{
                            color: "#8f8f8f",
                            fontWeight: 400,
                            fontSize: "13px",
                          }}
                        >
                          {data?.establishment_description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        {isOpen ? (
                          <Box
                            sx={{
                              border: " 2px solid #007E23",
                              bgcolor: "#E8FFE8",
                              color: "#007E23",
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                              fontSize: "13px",
                              px: 2,
                              py: 1,
                              borderRadius: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: "#007E23",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                              }}
                            />
                            Opened
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              border: " 2px solid #A71200",
                              bgcolor: "#FFE8E5",
                              color: "#A71200",
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                              fontSize: "13px",
                              px: 2,
                              py: 1,
                              borderRadius: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: "#A71200",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                              }}
                            />
                            Closed
                          </Box>
                        )}

                        {matchingDay && (
                          <Typography
                            color={darkMode ? "#F489FD" : "primary"}
                            sx={{ fontWeight: 500, fontSize: "13px" }}
                          >
                            Closes{" "}
                            {convertTo12HourFormat(matchingDay?.close_time)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={darkMode ? "dark" : "light"}
                  sx={{ mt: 1, py: 4, px: 3 }}
                >
                  <Box>
                    <Typography
                      className={darkMode ? "dark" : "light"}
                      sx={{ fontWeight: 700, fontSize: "16px" }}
                    >
                      Available Tables
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        color: darkMode ? "#fff" : "#8f8f8f",
                        fontSize: "13px",
                      }}
                    >
                      Browse specially curated table for our guests to allow you
                      instant place a reservations
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {data?.tables &&
                      data?.tables[0]?.menu_Item?.map((item, index) => (
                        <Link
                          to={`/reservation/${name}/${uid}?source=${source}`}
                          key={index}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              columnGap: 2,
                              p: 1,
                              border: "1px  solid #dedede",
                              borderRadius: "16px",
                              alignItems: "center",
                              mb: 2,
                              transition: "0.2s all linear",
                              "&:hover": {
                                border: "1px  solid #75007E",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                height: "94px",
                                width: "25%",
                                borderRadius: "6px",
                                background: `url('${item?.image}')`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />
                            <Box sx={{ height: "94px", width: "70%" }}>
                              <Typography
                                className={darkMode ? "dark" : "light"}
                                sx={{ fontWeight: 700, fontSize: "13px" }}
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                className={darkMode ? "dark" : "light"}
                                sx={{ fontWeight: 700, fontSize: "13px" }}
                              >
                                N{item?.unit_price.toLocaleString()}
                              </Typography>
                              <Typography
                                className="two-line-ellipsis"
                                sx={{
                                  fontSize: "12px",
                                  color: darkMode ? "#fff" : "#8f8f8f",
                                }}
                              >
                                {item?.description}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: darkMode ? "#fff" : "#8f8f8f",
                                }}
                              >
                                1 - {item?.max_guest_serving} people
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      ))}
                    <Link to={`/reservation/${name}/${uid}?source=${source}`}>
                      <Typography
                        color={darkMode ? "#F489FD" : "primary"}
                        sx={{
                          fontSize: "13px",
                          textDecoration: "underline",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        Can't find your ideal table? Click for reservations and
                        curate your own dining experience from our menu.
                      </Typography>
                    </Link>
                  </Box>
                </Box>

                <Box
                  sx={{
                    mt: 1,
                    height: "300px",
                    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${
                      data?.images && data?.images[0]?.image
                    }')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={leaf1} />
                    <Typography
                      sx={{ fontSize: "46px", color: "#fff", fontWeight: 700 }}
                    >
                      {data?.rating}
                    </Typography>
                    <img src={leaf2} />
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "38px",
                      fontFamily: "butler",
                      color: "#fff",
                      fontWeight: 700,
                      mt: 4,
                      textAlign: "center",
                      lineHeight: "37px",
                    }}
                  >
                    {data?.spot_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#fff",
                      fontWeight: 700,
                      mt: 3,
                      textTransform: "uppercase",
                    }}
                  >
                    {`${data?.short_location}, ${data?.city}`}
                  </Typography>
                </Box>
                <Box
                  className={darkMode ? "dark" : "light"}
                  sx={{ p: 3, minHeight: "250px", pb: 5 }}
                >
                  <Typography
                    className={darkMode ? "dark" : "light"}
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    Reviews - {data?.review_count}
                  </Typography>

                  <Box sx={{ mt: 2, px: 2 }}>
                    {data?.review?.map((item, index) => (
                      <Box
                        key={index}
                        sx={{ borderBottom: "1px solid #f4f4f4", pb: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 2,
                          }}  >
                          <Typography
                            className={darkMode ? "dark" : "light"}
                            sx={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            @{item?.customer_username}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={leaf3} />
                            <Typography
                              color={darkMode ? "#F489FD" : "primary"}
                              sx={{ fontSize: "12px", fontWeight: 500 }}
                            >
                              {item?.rating}
                            </Typography>
                            <img src={leaf4} />
                          </Box>
                        </Box>
                        <Typography
                          sx={{
                            mt: 0.5,
                            fontSize: "12px",
                            color: darkMode ? "#fff" : "#8f8f8f",
                          }}
                        >
                          {item?.review}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                    margin: "0 auto",
                    height: "100px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 0,
                    background: darkMode ? "#000" : "#fff",
                  }}
                >
                  <Link to={`/reservation/${name}/${uid}?source=${source}`}>
                    <Button
                      sx={{
                        width: "300px",
                        height: "63px",
                        background: darkMode ? "#fff" : "#000",
                        color: darkMode ? "#151515" : "#fff",
                        textTransform: "initial",
                        "&:hover": {
                          backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                        },
                        "&:disabled": {
                          color: "#fff",
                          bgcolor: "#5b5b5b",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      Curate Your Own Experience
                    </Button>
                  </Link>
                </Paper>
              </>
            )}
          </>
        )}
        <Box sx={{ display: "none" }}></Box>
      </Container>
    </>
  );
};

export default Reservation;
