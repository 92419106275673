import axios from "axios";

const url = process.env.REACT_APP_API_URL;


const apiClient = axios.create({
  baseURL: url,
  timeout: 120000,
});

apiClient.interceptors.request.use(
  function (config) {
    // config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

const waitlist = (email) => {
  return apiClient.post("/join-waitlist", { email });
};

// ReservationLink

const getRestaurantDetails = (uid) => {
  return apiClient.get(`/customer/reservation/vendor/${uid}`);
};

const getReservationDetails = (id, menu) => {
  return apiClient.put(`/customer/reservation/link/details/${id}`, { menu });
};


const reservationBookings = (
  id,
  menu,
  timing_details,
  full_name,
  email_address,
  phone_number,
  max_guest_size,
  reservation_lead
) => {
  const payload = {
    menu,
    timing_details,
    full_name,
    email_address,
    phone_number,
    max_guest_size,
    reservation_lead
  };

  console.log(payload)

  return apiClient.post(`/customer/reservation/link/${id}`, payload);
};
const walkinBookings = (
  id,
  menu,
  full_name,
  email_address,
  phone_number,
  reservation_lead,
  payment_option
) => {
  const payload = {
    menu,
    full_name,
    email_address,
    phone_number,
    reservation_lead,
    type:'walk_in_reservation',
    payment_option
  };

  console.log(payload)

  return apiClient.post(`/customer/reservation/link/${id}`, payload);
};

const checkPayment = (email, ref) =>{
  return apiClient.get(`/customer/check/reservation/status?transaction_reference=${ref}&email=${email}`)
}

export {
  waitlist,
  getRestaurantDetails,
  getReservationDetails,
  reservationBookings,walkinBookings,
  checkPayment
};
