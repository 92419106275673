import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  InputLabel,
  TextField,
  Button,
  Paper,
  ListItemButton,
} from "@mui/material";
import backIcon from "../Assets/img/back.svg";
import ScrollToTop from "../ScrollToTop";
import { reservationBookings, walkinBookings } from "../../assets/img/api";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "../Components/Alert";
import digitalIcon from "../Assets/img/digital.svg";
import offlineIcon from "../Assets/img/offline.svg";
import digitalIcon2 from "../Assets/img/active-digital.svg";
import offlineIcon2 from "../Assets/img/active-offline.svg";
import { useNavigate } from "react-router-dom";

const Overview = ({
  handleBack,
  details,
  totalBill,
  data,
  selectedMenu,
  uid,
  darkMode,
  source,
  walkin,
  selectedPayment,
}) => {
  const {
    fullName,
    email,
    phoneNumber,
    selectDate,
    selectTime,
    menuList,
    guestSize,
  } = details;

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [error_message, setErrMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleWalkin = async () => {
    setIsLoading(true);

    await walkinBookings(
      data?.id,
      selectedMenu,
      fullName,
      email,
      phoneNumber,
      source,
      selectedPayment
    )
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          console.log(res?.data);
          navigate(
            `/reserved?walkin=${selectedPayment}&uid=${uid}&reservation_id=${res?.data?.data?.reservation_id}`
          );
        }
      })
      .catch((err) => {
        setError(true);
        setErrMessage(err.response.data.message);
        setIsLoading(false);
      });
  };
  const handleDigital = async () => {
    setIsLoading(true);

    await walkinBookings(
      data?.id,
      selectedMenu,
      fullName,
      email,
      phoneNumber,
      source,
      selectedPayment
    )
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          const {
            amount,
            email,
            transaction_reference,
            reservation_id,
          } = res?.data?.data;

          const redirect_link = `https://www.vibezsup.com/reserved/?walkin=${selectedPayment}&uid=${uid}&transaction_reference=${transaction_reference}&email=${email}`;

   const pub_key =process.env.REACT_APP_POOLER_KEY;
          window?.Pooler?.Popup({
            amount,
            email,
            pub_key,
            redirect_link,
            transaction_reference,
          });
        }
      })
      .catch((err) => {
        setError(true);
        setErrMessage(err.response.data.message);

        setIsLoading(false);
      });
  };
  const handleBooking = async () => {
    setIsLoading(true);
    const time_details = `${selectDate?.fullDate} ${selectTime?.time24Hour}`;
    await reservationBookings(
      data?.id,
      selectedMenu,
      time_details,
      fullName,
      email,
      phoneNumber,
      guestSize,
      source
    )
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          const { amount, email, transaction_reference } = res?.data?.data;

          const redirect_link = `https://www.vibezsup.com/reserved/?walkin=true&reservation_id=${data}`;

          const pub_key =process.env.REACT_APP_POOLER_KEY;
          window?.Pooler?.Popup({
            amount,
            email,
            pub_key,
            redirect_link,
            transaction_reference,
          });
        }
      })
      .catch((err) => {
        setError(true);
        setErrMessage(err.response.data.message);

        setIsLoading(false);
      });
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: darkMode ? "#000" : "#fff",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
          margin: "0 auto",
          height: "130px",
          zIndex: 1000,
        }}
      >
        <Box sx={{ pt: 5, px: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <IconButton onClick={handleBack}>
              <img src={backIcon} />
            </IconButton>

            <Typography
              sx={{
                color: darkMode ? "#fff" : "#151515",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Order Overview
            </Typography>
          </Box>
          <Typography sx={{ mt: 2, color: darkMode ? "#fff" : "#5b5b5b" }}>
            Confirm order details before placing reservation
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: 3, mt: 15, pt: 3 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "12px",
            color: darkMode ? "#fff" : "#151515",
          }}
        >
          Total Bill
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            mt: 2,
            color: darkMode ? "#EA8072" : "#A71200",
          }}
        >
          N{totalBill.toLocaleString()}
        </Typography>
      </Box>
      <Box sx={{ mt: 3, px: 3 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            color: darkMode ? "#fff" : "#151515",
          }}
        >
          Menu Details
        </Typography>
        <Box>
          {details?.menuList?.map((item, index) => (
            <Box key={index} sx={{ mt: 3, mb: 2 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "12px",
                  color: darkMode ? "#fff" : "#151515",
                }}
              >
                {item?.name} - {item?.quantity}{" "}
                {item?.quantity > 2 ? "Pack" : "Packs"}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "11px",
                  mt: 1,
                  color: darkMode ? "#fff" : "#5b5b5b",
                }}
              >
                N{(item?.unit_price * item?.quantity).toLocaleString()}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
  {
    !walkin && (
          <Box
        sx={{
          borderTop: darkMode ? "5px solid #151515" : "5px solid #dedede",
          mt: 5,
          px: 3,
          pt: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            color: darkMode ? "#fff" : "#151515",
          }}
        >
          Timing Details
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            mt: 2,
            color: darkMode ? "#fff" : "#5b5b5b",
          }}
        >
          This table is being reserved for{" "}
          <span
            style={{ color: darkMode ? "#fff" : "#151515", fontWeight: 700 }}
          >
            {" "}
            {selectTime?.time12Hour}
          </span>{" "}
          on{" "}
          <span
            style={{ color: darkMode ? "#fff" : "#151515", fontWeight: 700 }}
          >
            {" "}
            {selectDate?.month} {selectDate?.date}
          </span>
        </Typography>
      </Box>
    )
  }
      <Box
        sx={{
          borderTop: darkMode ? "5px solid #151515" : "5px solid #dedede",
          mt: 5,
          px: 3,
          pt: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            color: darkMode ? "#fff" : "#151515",
          }}
        >
          Contact Details
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            mt: 1,
            color: darkMode ? "#fff" : "#5b5b5b",
          }}
        >
          Please provide the following information for your reservation.
        </Typography>
        <Box sx={{ width: "80%", mt: 3 }}>
          <InputLabel
            sx={{
              color: darkMode ? "#fff" : "#151515",
              fontSize: "12px",
              fontWeight: 500,
            }}
          >
            Full Name
          </InputLabel>
          <TextField
            margin="dense"
            value={fullName}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: darkMode ? "#151515" : "#f4f4f4",
                color: darkMode ? "#fff" : "#151515",
                border: darkMode ? "1px solid #fff" : "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
          <InputLabel
            sx={{
              color: darkMode ? "#fff" : "#151515",
              fontSize: "12px",
              fontWeight: 500,
              mt: 2,
            }}
          >
            Phone Number
          </InputLabel>
          <TextField
            margin="dense"
            value={phoneNumber}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: darkMode ? "#151515" : "#f4f4f4",
                color: darkMode ? "#fff" : "#151515",
                border: darkMode ? "1px solid #fff" : "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
          <InputLabel
            sx={{
              color: darkMode ? "#fff" : "#151515",
              fontSize: "12px",
              fontWeight: 500,
              mt: 2,
            }}
          >
            Email Address
          </InputLabel>
          <TextField
            margin="dense"
            value={email}
            fullWidth
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: darkMode ? "#151515" : "#f4f4f4",
                color: darkMode ? "#fff" : "#151515",
                border: darkMode ? "1px solid #fff" : "1px solid #151515",
                fontWeight: 500,
              },
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      {walkin ? (
        <>
          <Box
            sx={{
              borderTop: darkMode ? "5px solid #151515" : "5px solid #dedede",
              mt: 5,
              px: 3,
              pt: 4,
              height: "300px",
            }}
          >
            <Typography
              className={darkMode ? "dark" : "light"}
              sx={{ fontSize: "14px", fontWeight: 700 }}
            >
              Payment Option
            </Typography>
            <Typography
              sx={{
                color: darkMode ? "#fff" : "#5b5b5b",
                mt: 1,
                fontSize: "13px",
              }}
            >
              How do you intend to pay for the order?
            </Typography>

            <Box sx={{ mt: 3, width: "100%" }}>
              {selectedPayment === "digital_payment" && (
                <ListItemButton
                  selected={selectedPayment === "digital_payment"}
                  sx={{
                    border: "1.5px solid #dedede",
                    height: "70px",
                    borderRadius: "12px",
                    "&:hover": {
                      bgcolor: "#75007E1a",
                      borderColor: "primary.main",
                    },
                    ...(selectedPayment === "digital_payment" && {
                      borderColor: "primary.main",
                    }),
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <img
                      src={
                        selectedPayment === "digital_payment"
                          ? offlineIcon2
                          : offlineIcon
                      }
                    />
                    <Box>
                      <Typography
                        sx={{
                          color: darkMode ? "#fff" : "#151515",
                          fontWeight: 500,
                          ...(selectedPayment === "digital_payment" && {
                            color: "primary.main",
                          }),
                        }}
                      >
                        Digital Payment
                      </Typography>
                      <Typography
                        sx={{
                          color: darkMode ? "#fff" : "#5b5b5b",
                          mt: 1,
                          fontSize: "12px",
                          ...(selectedPayment === "digital_payment" && {
                            color: "primary.main",
                          }),
                        }}
                      >
                        Complete payment via bank transfer
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              )}

              {selectedPayment === "offline_payment" && (
                <ListItemButton
                  selected={selectedPayment === "offline_payment"}
                  sx={{
                    border: "1.5px solid #dedede",
                    height: "70px",
                    borderRadius: "12px",
                    mt: 3,
                    "&:hover": {
                      bgcolor: "#75007E1a",
                      borderColor: "primary.main",
                    },
                    ...(selectedPayment === "offline_payment" && {
                      borderColor: "primary.main",
                    }),
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <img
                      src={
                        selectedPayment === "offline_payment"
                          ? digitalIcon2
                          : digitalIcon
                      }
                    />
                    <Box>
                      <Typography
                        sx={{
                          color: darkMode ? "#fff" : "#151515",
                          fontWeight: 500,
                          ...(selectedPayment === "offline_payment" && {
                            color: "primary.main",
                          }),
                        }}
                      >
                        Offline Payment
                      </Typography>
                      <Typography
                        sx={{
                          color: darkMode ? "#fff" : "#5b5b5b",
                          mt: 1,
                          fontSize: "12px",
                          ...(selectedPayment === "offline_payment" && {
                            color: "primary.main",
                          }),
                        }}
                      >
                        Complete payment with Cash or POS
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              borderTop: darkMode ? "5px solid #151515" : "5px solid #dedede",
              mt: 5,
              px: 3,
              pt: 4,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                color: darkMode ? "#fff" : "#151515",
              }}
            >
              Guest Size
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                mt: 1,
                color: darkMode ? "#fff" : "#5b5b5b",
              }}
            >
              You are reserving for
              <b style={{ color: darkMode ? "#fff" : "#151515" }}>
                {" "}
                {guestSize} Guests{" "}
              </b>
            </Typography>
          </Box>

          <Box
            sx={{
              borderTop: darkMode ? "5px solid #151515" : "5px solid #dedede",
              mt: 5,
              px: 3,
              pt: 4,
              mb: 20,
            }}
          >
            <Typography
              sx={{
                color: darkMode ? "#EA8072" : "#A71200",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              Cancellation, Reschedule & Refund policy
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: darkMode ? "#fff" : "#5b5b5b",
                }}
              >
                <ul>
                  <li>
                    You can cancel this order up to 1 hour before the
                    reservation time. After that, a 15% cancellation fee
                    applies, and the rest is reversed to the originating credit
                    account.
                  </li>
                  <li>
                    The service fee of N1,000 is irrefundable regardless of the
                    cancellation time.
                  </li>
                  <li>
                    Reservations can also be rescheduled for future dates and
                    should be done up to 1 hour before the reservation time.
                    After that, a 15% charge would apply to reschedule the
                    reservation.
                  </li>
                  <li>
                    If the reservation remains unfulfilled without a reschedule
                    or cancellation 3hours after the reservation time, the
                    reservation is automatically cancelled and the 15%
                    cancellation fee rule applies or 15% reschedule fee if you
                    chose to reschedule.
                  </li>
                  <li>
                    To reschedule or cancel this booking, please reachout to
                    support@vibezsup.com
                  </li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
          margin: "0 auto",
          height: "100px",
          display: "grid",
          placeItems: "center",
          borderRadius: 0,
          background: darkMode ? "#000" : "#fff",
        }}
      >
        {isLoading ? (
          <>
            <LoadingButton
              disabled
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              sx={{
                px: 6,
                height: "63px",
                background: darkMode ? "#fff" : "#000",
                color: darkMode ? "#151515" : "#fff",
                textTransform: "initial",
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
                },
                "&:disabled": {
                  color: "#fff",
                  bgcolor: "#5b5b5b",
                  cursor: "not-allowed",
                },
              }}
            >
              Placing Reservation...
            </LoadingButton>
          </>
        ) : (
          <Button
            onClick={() => {
              if (walkin && selectedPayment === "offline_payment") {
                handleWalkin();
              } else if (walkin && selectedPayment === "digital_payment") {
                handleDigital();
              } else {
                handleBooking();
              }
            }}
            sx={{
              width: "300px",
              height: "63px",
              background: darkMode ? "#fff" : "#000",
              color: darkMode ? "#151515" : "#fff",
              textTransform: "initial",
              fontWeight: 700,
              "&:hover": {
                backgroundColor: darkMode ? "#ffffffb7" : "#151515b7",
              },
              "&:disabled": {
                color: "#fff",
                bgcolor: "#5b5b5b",
                cursor: "not-allowed",
              },
            }}
          >
            {selectedPayment === "offline_payment"
              ? "Place Order"
              : `Make Payment - N${totalBill.toLocaleString()}`}
          </Button>
        )}
      </Paper>

      <Alert
        open={error}
        handleClose={() => setError(false)}
        message={error_message}
      />
    </>
  );
};

export default Overview;
