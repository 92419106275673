import React, { useState, useEffect } from "react";
import Container from "../Container";
import {
  Box,
  Typography,
  Paper,
  Skeleton,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import succ from "../Assets/img/leisure _ vacation, holiday, offline, island, summer, tropical, man, people, drink, beverage.svg";
import { useParams, useNavigate, Link } from "react-router-dom";
import { checkPayment, getRestaurantDetails } from "../../assets/img/api";
import failedImg from "../Assets/img/security _ protection, safety, umbrella, shield, alert, message, antivirus, notification, malware.svg";

import Pusher from "pusher-js";
import Receipt from "./Receipt";

const Reserved = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isOverage, setIsOverage] = useState(false);
  const [failed, setFailed] = useState(false);
  const [underPaid, setUnderPaid] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const walkinParam = urlParams.get("walkin");
  const reservation_id = urlParams.get("reservation_id");
  const emailParam = urlParams.get("email");
  const transaction_reference = urlParams.get("transaction_reference");
  const uid = urlParams.get("uid");

  console.log(emailParam);

  useEffect(() => {
    if (walkinParam !== "offline_payment") {
      setIsLoading(true);
      if (emailParam) {
        setTimeout(() => {
          handlePaymentCheck();
        }, 10000);
      }
    }
  }, [walkinParam, emailParam]);

  const handleGetRestaurantDetails = async () => {
    await getRestaurantDetails(uid)
      .then((res) => {
        setData(res?.data?.data);
        if (res?.data?.status === false) {
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetRestaurantDetails();
  }, []);

  const { reference } = useParams();

  const [reciept, setReciept] = useState(null);

  const [isPending, setPending] = useState(false);
  const handlePaymentCheck = async () => {
    setIsLoading(true);
    await checkPayment(emailParam, transaction_reference)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res?.data?.status) {
          setReciept(res?.data?.data?.receipt);
          const { payment_status, reservation_status } = res?.data?.data;
          if (
            (reservation_status === "vendor_approved" ||
              reservation_status === "checked_in") &&
            payment_status === "fully_paid"
          ) {
            setIsCompleted(true);
          }
          if (reservation_status === "pending") {
            setPending(true);
          }

          if (payment_status === "over_paid") {
            setIsOverage(true);
            setUnderPaid(false);
          }

          if (res?.data?.data?.reservation_status === "vendor_approved") {
            setIsCompleted(true);
            setFailed(false);
          }
          if (payment_status == "under_paid") {
            setUnderPaid(true);
            setIsOverage(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (isPending) {
      handlePaymentCheck();
    }
  }, [isPending]);


  const DIGITAL = walkinParam === "digital_payment"
  const OFFLINE = walkinParam === "offline_payment"

  console.log(DIGITAL)
  return (
    <>
      <Container>
        {isLoading ? (
          <>
            <Box
              sx={{
                height: "100vh",
                background: "#fff",
                px: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                Confirming reservation and payment...
              </Typography>
              <LinearProgress sx={{ width: "70%", mt: 1 }} />
            </Box>

          </>
        ) : (
          <>
            {OFFLINE ? (
              <>
                <Box
                  sx={{
                    height: "100vh",
                    background: darkMode ? "#000" : "#fff",
                    px: 3,
                  }}
                >
                  <Box sx={{ pt: 10, display: "grid", placeItems: "center" }}>
                    {isLoading ? (
                      <Skeleton
                        variant="rounded"
                        sx={{ width: "80px", height: "80px" }}
                      />
                    ) : (
                      <a href={`https://www.vibezsup.com/${data?.online_reservation_link}`}>
                      <img src={data?.logo} width={70} />
                      </a>
                    )}
                  </Box>

                  <Box
                    sx={{
                      height: "60vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <img src={succ} width={180} />
                    <Typography
                      sx={{
                        fontFamily: "butler",
                        fontWeight: 900,
                        fontSize: "24px",
                        mt: 4,
                        color: darkMode ? "#fff" : "#151515",
                      }}
                    >
                      Order {reservation_id} Booked
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: darkMode ? "#fff" : "#5b5b5b",
                        textAlign: "center",
                        margin: "0 auto",
                        mt: 2,
                        width: "90%",
                      }}
                    >
                      Your order has been successfully submitted. An email
                      containing your receipt will be sent once you complete
                      payment with the waiter for your reference.
                    </Typography>
                  </Box>
       
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  height: "100vh",
                  background: darkMode ? "#000" : "#fff",
                  px: 3,
                }}
              >
                <Box sx={{ pt: 10, display: "grid", placeItems: "center" }}>
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ width: "80px", height: "80px" }}
                    />
                  ) : (
                       <a href={`https://www.vibezsup.com/${data?.online_reservation_link}`}>
                      <img src={data?.logo} width={70} />
                      </a>
                  )}
                </Box>

                <Box
                  sx={{
                    height: "60vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  {isCompleted && DIGITAL && (
                    <>
                      <img src={succ} width={180} />
                      <Typography
                        sx={{
                          fontFamily: "butler",
                          fontWeight: 900,
                          fontSize: "24px",
                          mt: 4,
                          color: darkMode ? "#fff" : "#151515",
                        }}
                      >
                        Order {reciept?.reservation_id} Booked
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: darkMode ? "#fff" : "#5b5b5b",
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                     Your order has been successfully submitted. An email containing your receipt has been sent for your reference.
                      </Typography>
                      {!reciept ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress size={10} />
                          <Typography sx={{ mt: 4, fontSize: "12px" }}>
                            Loading Receipt...
                          </Typography>
                        </Box>
                      ) : (
                        <Receipt receiptData={reciept} />
                      )}
                    </>
                  ) } 
              {
                     isCompleted && !DIGITAL && (
                        <>
                          <img src={succ} width={200} />
                          <Typography
                            sx={{
                              fontFamily: "butler",
                              fontWeight: 900,
                              fontSize: "30px",
                              mt: 4,
                              color: darkMode ? "#fff" : "#151515",
                            }}
                          >
                            Reservation Booked
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: darkMode ? "#fff" : "#5b5b5b",
                              textAlign: "center",
                              mt: 1,
                            }}
                          >
                            Your reservation has been successfully submitted. An
                            email has been sent containing details of this
                            booking for your reference.
                          </Typography>
                          {!reciept ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <CircularProgress size={10} />
                              <Typography sx={{ mt: 4, fontSize: "12px" }}>
                                Loading Receipt...
                              </Typography>
                            </Box>
                          ) : (
                            <Receipt receiptData={reciept} />
                          )}
                        </>
                      )
              }

                  {failed && DIGITAL  &&  (
                    <>
                      <img src={failedImg} width={200} />
                      <Typography
                        sx={{
                          fontFamily: "butler",
                          fontWeight: 900,
                          fontSize: "25px",
                          mt: 4,
                          color: darkMode ? "#fff" : "#151515",
                        }}
                      >
             Order Booking failed
                      </Typography>
                    </>
                  )}
                  
                  { failed && !DIGITAL(
 
                    <>
                        <img src={failedImg} width={200} />
                      <Typography
                        sx={{
                          fontFamily: "butler",
                          fontWeight: 900,
                          fontSize: "25px",
                          mt: 4,
                          color: darkMode ? "#fff" : "#151515",
                        }}
                      >
                        Reservation Booking failed
                      </Typography>
                    </>
                  )
                
                }

                  {underPaid && DIGITAL &&  (
                    <>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: darkMode ? "#fff" : "#5b5b5b",
                          textAlign: "center",
                          mt: 3,
                        }}
                      >
                     The amount received was short of the total reservation fee. Kindly reach out to <b>the waiter</b> to process a total refund or balance the shortfall.
                      </Typography>
                    </>
                  )}
                  
                  {
                  underPaid && !DIGITAL &&
                  (
                    <>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: darkMode ? "#fff" : "#5b5b5b",
                          textAlign: "center",
                          mt: 3,
                        }}
                      >
                        The amount received was short of the total reservation
                        fee. Kindly reach out to{" "}
                        <a
                          href="mailto:support@vibezsup.com"
                          style={{
                            fontWeight: 700,
                            color: darkMode ? "#fff" : "#151515",
                          }}
                        >
                          support@vibezsup.com
                        </a>{" "}
                        to process a total refund.
                      </Typography>
                    </>
                  )
                
                }
                  {isOverage && DIGITAL &&  (
                    <>
                      <img src={succ} width={200} />
                      <Typography
                        sx={{
                          fontFamily: "butler",
                          fontWeight: 900,
                          fontSize: "30px",
                          mt: 4,
                          color: darkMode ? "#fff" : "#151515",
                        }}
                      >
                     Order {reciept?.reservation_id}  Booked
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: darkMode ? "#fff" : "#5b5b5b",
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                      Your order has been successfully submitted. An email containing your receipt has been sent for your reference.
                      </Typography>
                      <Box
                        sx={{
                          mt: 3,
                          border: "0.5px solid  #C56000",
                          bgcolor: "#FFF2E5",
                          color: "#C56000",
                          borderRadius: "12px",
                          p: 2,
                          boxSizing: "border-box",
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: 400,
                        }}
                      >
                    We've also observed an overpayment in the amount received from your payment. Please reach out to <b>the waiter</b> to assist with reversal of the excess funds. 
                      </Box>
                      {!reciept ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress size={10} />
                          <Typography sx={{ mt: 4, fontSize: "12px" }}>
                            Loading Receipt...
                          </Typography>
                        </Box>
                      ) : (
                        <Receipt receiptData={reciept} />
                      )}
                    </>
                  )}
                  
                  
                  {isOverage && !DIGITAL
                  (
                    <>
                       <img src={succ} width={200} />
                      <Typography
                        sx={{
                          fontFamily: "butler",
                          fontWeight: 900,
                          fontSize: "30px",
                          mt: 4,
                          color: darkMode ? "#fff" : "#151515",
                        }}
                      >
                        Reservation Booked
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: darkMode ? "#fff" : "#5b5b5b",
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                        Your order has been successfully submitted. An email
                        containing your receipt has been sent for your
                        reference.
                      </Typography>
                      <Box
                        sx={{
                          mt: 3,
                          border: "0.5px solid  #C56000",
                          bgcolor: "#FFF2E5",
                          color: "#C56000",
                          borderRadius: "12px",
                          p: 2,
                          boxSizing: "border-box",
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: 400,
                        }}
                      >
                        We've also observed an overpayment in the amount
                        received from your payment. Please reach out to{" "}
                        <a
                          href="mailto:support@vibezsup.com"
                          style={{ fontWeight: 700, color: "#c56000" }}
                        >
                          support@vibezsup.com
                        </a>{" "}
                        to initiate the reversal of the excess funds. T&Cs apply
                      </Box>
                      {/* {!reciept ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress size={10} />
                          <Typography sx={{ mt: 4, fontSize: "12px" }}>
                            Loading Receipt...
                          </Typography>
                        </Box>
                      ) : (
                        <Receipt receiptData={reciept} />
                      )} */}
                    </>
                  )
                
                }
                </Box>

              </Box>
            )}
          </>
        )}

                        <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                    margin: "0 auto",
                    height: "70px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 0,
                    background: darkMode ? "#000" : "#fff",
                    borderTop: "none",
                    boxShadow:'none'
                  }}
                >
                  <Link to="/">
                  <Typography
                    color="primary"
                    sx={{
                      fontWeight: 500,
                      fontSize: "10px",
                      bgcolor: "#FCEDFE",
                      width: "120px",
                    height:'26px',
                      borderRadius: "50px",
                 display:'grid',
                 placeContent:'center'
                    }}
                  >
                    Powered by VibezsUp
                  </Typography>
                  </Link>
                </Paper>
      </Container>
    </>
  );
};

export default Reserved;
